import React from 'react'
import styles from "../account.module.css";
import Select from 'react-select';

const BusinessInformation = ({ heading, subHeading }) => {

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '4px',
        }),
    };

    const accounts = [
        { value: 'notSure', label: 'Not Sure' },
        { value: 'savings', label: 'Savings' },
        { value: 'current', label: 'Current' },
    ];
    const defaultAcct = { value: 'notSure', label: 'Not Sure' };

    return (
        <section className={styles.sectionWrapper}>

            <h4 className={styles.headingTxt}>{heading}</h4>
            <p className={styles.subHeadingTxt}>{subHeading}</p>
            
            <form className={styles.formWrapper}>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="businessAddress" className={styles.label}>business address</label>
                        {/* <span>*</span> */}
                        <input type="text" name="businessAddress" className={styles.input} />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="state" className={styles.label}>state</label>
                        <input type="text" name="state" className={styles.input} />
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="city" className={styles.label}>city</label>
                        <input type="text" name="city" className={styles.input} />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="website" className={styles.label}>business website</label>
                        {/* <span>*</span> */}
                        <input type="url" name="website" className={styles.input} />
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="businessEmail" className={styles.label}>business email</label>
                        {/* <span>*</span> */}
                        <input type="email" name="businessEmail" className={styles.input} />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="facebookUsername" className={styles.label}>facebook username</label>
                        {/* <span>*</span> */}
                        <input type="text" name="facebookUsername" className={styles.input} />
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="instagramHandle" className={styles.label}>instagram handle</label>
                        {/* <span>*</span> */}
                        <input type="text" name="instagramHandle" className={styles.input} />
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="twitterHandle" className={styles.label}>twitter handle</label>
                        {/* <span>*</span> */}
                        <input type="text" name="twitterHandle" className={styles.input} />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="accountType" className={styles.label}>account type</label>
                        {/* <span>*</span> */}
                        <Select
                            options={accounts}
                            defaultValue={defaultAcct}
                            styles={customStyles}
                        />
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="bankName" className={styles.label}>bank name</label>
                        {/* <span>*</span> */}
                        <input type="text" name="bankName" className={styles.input} />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="accountNumber" className={styles.label}>account number</label>
                        {/* <span>*</span> */}
                        <input type="number" name="accountNumber" className={styles.input} />
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="accountName" className={styles.label}>account name</label>
                        {/* <span>*</span> */}
                        <input type="text" name="accountName" className={styles.input} />
                    </div>
                </div>

                <div className={styles.row}>
                    <div style={{ width: "100%" }}>
                        <label htmlFor="uploadLogo" className={styles.label}>Upload CAC Document</label>
                        <input type="file" className="form-control" id="uploadLogo" />
                    </div>
                </div>

                <div className={styles.row}>
                    <button type='button' className={styles.backBtn}>
                        back
                    </button>
                    <button type='submit' className={styles.saveBtn}>Save and Continue</button>
                </div>
            </form>
        </section>
    )
}

export default BusinessInformation