import styles from "./filter.module.css"

import { AiOutlinePlusSquare } from 'react-icons/ai';
import { SiPlatformdotsh } from 'react-icons/si';
import { GrStatusUnknown } from 'react-icons/gr';
import { MdDateRange } from 'react-icons/md';

import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropup } from 'react-icons/io';
import { IoMdCheckmarkCircle } from 'react-icons/io';

import { useRef, useState } from "react";

import { useSearchParams } from 'react-router-dom';

const Filter = () => {

    const [filterMode, setFilterMode] = useState(false)

    const referenceRef = useRef()
    const amountRef = useRef()
    const recipientRef = useRef()
    const customerEmailRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams();

    const defaultFilter = {
        date: "all",
        status: "all",
        channel: "all",
        reference: false,
        amount: false,
        recipient: false,
        customerEmail: false,
    }
    const defaultOpen = {
        date: false,
        status: false,
        channel: false,
        custom: false
    }
    const [isOpen, setIsOpen] = useState(defaultOpen)
    const [filterVal, setFilterVal] = useState(defaultFilter)

    const handleSubmit = () => {
        const query = {
            date: filterVal.date ? filterVal.date : undefined,
            status: filterVal.status ? filterVal.status : undefined,
            channel: filterVal.channel ? filterVal.channel : undefined,
            reference: filterVal.reference ? filterVal.reference : undefined,
            amount: filterVal.amount ? filterVal.amount : undefined,
            recipient: filterVal.recipient ? filterVal.recipient : undefined,
            customerEmail: filterVal.customerEmail ? filterVal.customerEmail : undefined,
        };
        setSearchParams(query);
        console.log("checking query", query)
        setIsOpen(defaultOpen)
    };

    const handleIsOpen = (section) => {
        setIsOpen({ ...defaultOpen, [section]: !isOpen[section] })
    }

    const handleFilterToggle = () => {
        setFilterMode(prev => !prev)
    }

    const handleChange = (type, ref = "custom") => {
        if (type === "date") {
            setFilterVal(prev => ({ ...prev, [type]: ref }));
        }
        if (type === "status") {
            setFilterVal(prev => ({ ...prev, [type]: ref }));
        }
        if (type === "channel") {
            setFilterVal(prev => ({ ...prev, [type]: ref }));
        }
        if (type === "reference") {
            setFilterVal(prev => ({ ...prev, [type]: referenceRef.current.value }));
        }
        if (type === "amount") {
            setFilterVal(prev => ({ ...prev, [type]: amountRef.current.value }));
        }
        if (type === "recipient") {
            setFilterVal(prev => ({ ...prev, [type]: recipientRef.current.value }));
        }
        if (type === "customerEmail") {
            setFilterVal(prev => ({ ...prev, [type]: customerEmailRef.current.value }));
        }
    };

    return (
        <section className={styles.filterContainer}>

            <div style={{ marginRight: "1em" }}>
                <label className="form-check-label" style={{ marginRight: "4px" }}> Toggle Filter</label>
                <input
                    type="checkbox"
                    checked={filterMode}
                    onChange={handleFilterToggle}
                    className="form-check-input"
                />
            </div>

            {filterMode && <form className={styles.filterForm} onSubmit={(evt) => evt.preventDefault()}>

                {/* Date Group */}
                <div className={styles.filterGroup} >
                    <button className={styles.selectedBtn} onClick={() => handleIsOpen("date")}><MdDateRange className={styles.icon} /><span>date</span>{!isOpen.date ? <IoMdArrowDropdown className={styles.icon} /> : <IoMdArrowDropup className={styles.icon} /> }</button>
                    {isOpen.date && <div className={styles.dropdown}>
                        {/* day */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="allDate"
                                onChange={() => handleChange("date", "all")}
                                checked={filterVal.date === "all"}
                            />
                            <label
                                className="form-check-label  mx-1 mb-2" htmlFor="all">
                                All
                            </label>
                        </div>
                        {/* today */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="todayDate"
                                onChange={() => handleChange("date", "today")}
                                checked={filterVal.date === "today"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="todayDate">
                                today
                            </label>
                        </div>
                        {/* last 7 days */}
                        <div className="form-check hover-dark">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="last7Days"
                                onChange={() => handleChange("date", "last7Days")}
                                checked={filterVal.date === "last7Days"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="last7Days">
                                last 7 days
                            </label>
                        </div>
                        {/* this month */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input mb-1"
                                id="thisMonth"
                                onChange={() => handleChange("date", "thisMonth")}
                                checked={filterVal.date === "thisMonth"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="thisMonth">
                                this month
                            </label>
                        </div>
                        {/* this year */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="thisYear"
                                onChange={() => handleChange("date", "thisYear")}
                                checked={filterVal.date === "thisYear"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="thisYear">
                                this year
                            </label>
                        </div>
                    </div>}

                </div>

                {/* StatusGroup */}
                <div className={styles.filterGroup}>
                    <button className={styles.selectedBtn} onClick={() => handleIsOpen("status")}><GrStatusUnknown className={styles.icon} /><span>status</span>{!isOpen.status ? <IoMdArrowDropdown className={styles.icon} /> : <IoMdArrowDropup className={styles.icon} /> }</button>
                    {isOpen.status && <div className={styles.dropdown}>
                        {/* all */}
                        <div className="form-check">
                            <input
                                name="status"
                                type="radio"
                                className="form-check-input"
                                id="statusAll"
                                onChange={() => handleChange("status", "all")}
                                checked={filterVal.status === "all"}
                            />
                            <label className="form-check-label" htmlFor="statusAll">
                                All
                            </label>
                        </div>
                        {/* successful */}
                        <div className="form-check">
                            <input
                                name="status"
                                type="radio"
                                className="form-check-input"
                                id="successful"
                                onChange={() => handleChange("status", "successful")}
                                checked={filterVal.status === "successful"}
                            />
                            <label className="form-check-label" htmlFor="successful">
                                successful
                            </label>
                        </div>
                        {/*failed*/}
                        <div className="form-check">
                            <input
                                name="status"
                                type="radio"
                                className="form-check-input"
                                id="failed"
                                onChange={() => handleChange("status", "failed")}
                                checked={filterVal.status === "failed"}
                            />
                            <label className="form-check-label" htmlFor="failed">
                                failed
                            </label>
                        </div>
                        {/* pending */}
                        <div className="form-check">
                            <input
                                name="status"
                                type="radio"
                                className="form-check-input"
                                id="pending"
                                onChange={() => handleChange("status", "pending")}
                                checked={filterVal.status === "pending"}
                            />
                            <label className="form-check-label" htmlFor="pending">
                                pending
                            </label>
                        </div>
                        {/* reversed */}
                        <div className="form-check">
                            <input
                                name="status"
                                type="radio"
                                className="form-check-input"
                                id="reversed"
                                onChange={() => handleChange("status", "reversed")}
                                checked={filterVal.status === "reversed"}
                            />
                            <label className="form-check-label" htmlFor="reversed">
                                reversed
                            </label>
                        </div>
                        {/* abandoned */}
                        <div className="form-check">
                            <input
                                name="status"
                                type="radio"
                                className="form-check-input"
                                id="abandoned"
                                onChange={() => handleChange("status", "abandoned")}
                                checked={filterVal.status === "abandoned"}
                            />
                            <label className="form-check-label" htmlFor="abandoned">
                                abandoned
                            </label>
                        </div>
                    </div>}

                </div>

                {/* channel */}
                <div className={styles.filterGroup}>
                    <button className={styles.selectedBtn} onClick={() => handleIsOpen("channel")}><SiPlatformdotsh className={styles.icon} /><span>channel</span>{!isOpen.channel ? <IoMdArrowDropdown className={styles.icon} /> : <IoMdArrowDropup className={styles.icon} /> }</button>
                    {isOpen.channel && <div className={styles.dropdown}>
                        {/* all */}
                        <div className="form-check">
                            <input
                                name="channel"
                                type="radio"
                                className="form-check-input"
                                id="all"
                                onChange={() => handleChange("channel", "all")}
                                checked={filterVal.channel === "all"}
                            />
                            <label className="form-check-label" htmlFor="all">
                                all
                            </label>
                        </div>
                        {/* card */}
                        <div className="form-check">
                            <input
                                name="channel"
                                type="radio"
                                className="form-check-input"
                                id="card"
                                onChange={() => handleChange("channel", "card")}
                                checked={filterVal.channel === "card"}
                            />
                            <label className="form-check-label" htmlFor="card">
                                card
                            </label>
                        </div>
                        {/*bank*/}
                        <div className="form-check">
                            <input
                                name="channel"
                                type="radio"
                                className="form-check-input"
                                id="bank"
                                onChange={() => handleChange("channel", "bank")}
                                checked={filterVal.channel === "bank"}
                            />
                            <label className="form-check-label" htmlFor="bank">
                                bank
                            </label>
                        </div>
                        {/* ussd */}
                        <div className="form-check">
                            <input
                                name="channel"
                                type="radio"
                                className="form-check-input"
                                id="ussd"
                                onChange={() => handleChange("channel", "ussd")}
                                checked={filterVal.channel === "ussd"}
                            />
                            <label className="form-check-label" htmlFor="ussd">
                                USSD
                            </label>
                        </div>
                        {/* bank transfer */}
                        <div className="form-check">
                            <input
                                name="channel"
                                type="radio"
                                className="form-check-input"
                                id="bankTransfer"
                                onChange={() => handleChange("channel", "bankTransfer")}
                                checked={filterVal.channel === "bankTransfer"}
                            />
                            <label className="form-check-label" htmlFor="bankTransfer">
                                bank transfer
                            </label>
                        </div>
                        {/* pos */}
                        <div className="form-check">
                            <input
                                name="channel"
                                type="radio"
                                className="form-check-input"
                                id="pos"
                                onChange={() => handleChange("channel", "pos")}
                                checked={filterVal.channel === "pos"}
                            />
                            <label className="form-check-label" htmlFor="pos">
                                pos
                            </label>
                        </div>
                    </div>}

                </div>

                {/* others */}
                <div className={styles.filterGroup}>
                    <button className={styles.selectedBtn} onClick={() => handleIsOpen("custom")}><AiOutlinePlusSquare className={styles.icon} /> <span>more</span>{!isOpen.custom ? <IoMdArrowDropdown className={styles.icon} /> : <IoMdArrowDropup className={styles.icon} /> }</button>
                    {isOpen.custom && <div className={styles.customDropdown}>

                        {/* reference */}
                        <div className={styles.fullBox}>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    name="reference"
                                    id="reference"
                                    placeholder="Reference"
                                    className={styles.customInput}
                                    ref={referenceRef}
                                />
                                 {filterVal.reference?.length > 0 ? <IoMdCheckmarkCircle size={20} color="green" className={styles.checkmarkIcon} /> : ""}
                            </div>
                            <button
                                className={styles.filterBtn}
                                onClick={() => handleChange("reference")}
                            >Apply</button>
                        </div>

                        {/* amount */}
                        <div className={styles.fullBox}>
                            <div className={styles.inputBox}>
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    placeholder="Amount"
                                    className={styles.customInput}
                                    ref={amountRef}
                                />
                                 {filterVal.amount?.length > 0 ? <IoMdCheckmarkCircle size={20} color="green" className={styles.checkmarkIcon} /> : ""}
                            </div>
                            <button className={styles.filterBtn} onClick={() => handleChange("amount")}>Apply</button>
                        </div>

                        {/* recipient */}
                        <div className={styles.fullBox}>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    name="recipient"
                                    id="recipient"
                                    placeholder="Recipient"
                                    className={styles.customInput}
                                    ref={recipientRef}
                                />
                                 {filterVal.recipient?.length > 0 ? <IoMdCheckmarkCircle size={20} color="green" className={styles.checkmarkIcon} /> : ""}
                            </div>
                            <button className={styles.filterBtn} onClick={() => handleChange("recipient")}>Apply</button>
                        </div>

                        {/* customerEmail */}
                        <div className={styles.fullBox}>
                            <div className={styles.inputBox}>
                                <input
                                    type="email"
                                    name="customerEmail"
                                    id="customerEmail"
                                    placeholder="Customer Email"
                                    className={styles.customInput}
                                    ref={customerEmailRef}
                                />
                                 {filterVal.customerEmail?.length > 0 ? <IoMdCheckmarkCircle size={20} color="green" className={styles.checkmarkIcon} /> : ""}
                            </div>
                            <button className={styles.filterBtn} onClick={() => handleChange("customerEmail")}>Apply</button>

                        </div>

                    </div>}

                </div>

                <button className={styles.selectedBtn} onClick={handleSubmit} style={{backgroundColor: "#2A5885", color: "#fff"}}>SUBMIT</button>

            </form>}
        </section >
    )
}

export default Filter