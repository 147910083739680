import Navbar from "./components/landing/navbar/Navbar"
import Landing from "./pages/landing/Landing"
import AccountPage from "./pages/dashboard/account/AccountPage"
import { Routes, Route, useLocation } from "react-router-dom"
import OverviewPage from "./pages/dashboard/overview/OverviewPage";
import DevToolsPage from "./pages/dashboard/dev-tools/DevToolsPage";
import Auth from "./pages/auth/Auth";
import TransactionsPage from "./pages/dashboard/transactions/TransactionsPage";
import Reset from "./pages/auth/Reset";
import ResetEmailSuccess from "./pages/auth/ResetSuccess";
import SettlementsPage from "./pages/dashboard/settlements/SettlementsPage";
// import { useContext, useEffect, useState } from "react";
import Protected from "./protected/Protected"
import NotFoundDashboard from "./pages/dashboard/404Dashboard";
import NotFoundLanding from "./pages/404Landing";
import About from "./pages/about";
import ComingSoonDashboard from "./pages/dashboard/ComingSoon";

import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from "./redux/slices/authSlice"
import LandingAlt from "./pages/landing/LandingAlt";

function App() {

  const location = useLocation();
  const currentPathname = location.pathname;

  const isLoggedIn = useSelector(selectIsLoggedIn)

  return (

    <section className="main-container">
      {/* {[].includes(currentPathname) || "/" === currentPathname ? < Navbar /> :
        null
      } */}

      <Routes>
        <Route path="/" element={<LandingAlt />} />
        {/* <Route path="/test" element={<LandingAlt />} /> */}

        <Route path="/about" element={<About />} />

        <Route
          path="/login"
          element={<Auth page="login" />}
        />

        <Route
          path="/register"
          element={<Auth page="register" />}
        />

        <Route
          path="/reset-password"
          element={<Reset />}
        />

        <Route
          path="/reset-success"
          element={<ResetEmailSuccess />}
        />


        <Route path="/dashboard"
          element={<Protected isLoggedIn={isLoggedIn} >
            {/* <AccountPage user={userData} /> */}
            <AccountPage />
          </Protected>
          }
        />

        <Route path="/dashboard/overview"
          element={<Protected isLoggedIn={isLoggedIn} >
            {/* <OverviewPage user={userData} /> */}
            <OverviewPage />
          </Protected>
          } />

        <Route path="/dashboard/developer-tools"
          element={<Protected isLoggedIn={isLoggedIn} >
            {/* <DevToolsPage user={userData} /> */}
            <DevToolsPage />
          </Protected>
          }
        />

        <Route path="/dashboard/transactions"
          element={< Protected isLoggedIn={isLoggedIn} >
            {/* <TransactionsPage user={userData} /> */}
            <TransactionsPage />
          </Protected>
          } />

        <Route path="/dashboard/settlements"
          element={< Protected isLoggedIn={isLoggedIn}>
            {/* <SettlementsPage user={userData} /> */}
            <SettlementsPage />
          </Protected>
          } />

        <Route path="/dashboard/customers"
          element={< Protected isLoggedIn={isLoggedIn}>
            <ComingSoonDashboard />
          </Protected>
          } />

        <Route path="/dashboard/support"
          element={< Protected isLoggedIn={isLoggedIn}>
            <ComingSoonDashboard />
          </Protected>
          } />


        <Route path="/dashboard/*" element={<NotFoundDashboard />} />
        <Route path="/*" element={<NotFoundLanding />} />
      </Routes>

    </section>

  )
}

export default App