import styles from "./filter.module.css"

import { AiOutlinePlusSquare } from 'react-icons/ai';
import { SiPlatformdotsh } from 'react-icons/si';
import { GrStatusUnknown } from 'react-icons/gr';
import { MdDateRange } from 'react-icons/md';

import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropup } from 'react-icons/io';
import { IoMdCheckmarkCircle } from 'react-icons/io';

import { useState, useRef } from "react";

import { useSearchParams } from 'react-router-dom';

const Filter = () => {
    const [filterMode, setFilterMode] = useState(false)

    const referenceRef = useRef()
    const amountRef = useRef()
    const accountRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams();

    const defaultOpen = {
        date: false,
        custom: false
    }
    const [isOpen, setIsOpen] = useState(defaultOpen)

    const defaultFilter = {
        date: "all",
        account: false,
        amount: false,
        reference: false,
    }
    const [filterVal, setFilterVal] = useState(defaultFilter)

    const handleSubmit = () => {
        const query = {
            date: filterVal.date ? filterVal.date : undefined,
            account: filterVal.account ? filterVal.account : undefined,
            amount: filterVal.amount ? filterVal.amount : undefined,
            reference: filterVal.reference ? filterVal.reference : undefined,
        };
        setSearchParams(query);
        console.log("checking query", query)
        setIsOpen(defaultOpen)
    };

    const handleIsOpen = (section) => {
        setIsOpen({ ...defaultOpen, [section]: !isOpen[section] })
    }

    const handleFilterToggle = () => {
        setFilterMode(prev => !prev)
    }

    const handleChange = (type, ref = "custom") => {
        if (type === "date") {
            setFilterVal(prev => ({ ...prev, [type]: ref }));
        }
        if (type === "reference") {
            setFilterVal(prev => ({ ...prev, [type]: referenceRef.current.value }));
        }
        if (type === "amount") {
            setFilterVal(prev => ({ ...prev, [type]: amountRef.current.value }));
        }
        if (type === "account") {
            setFilterVal(prev => ({ ...prev, [type]: accountRef.current.value }));
        }
    };

    return (
        <section className={styles.filterContainer}>

            <div style={{ marginRight: "1em" }}>
                <label className="form-check-label" style={{ marginRight: "4px" }}> Toggle Filter</label>
                <input
                    type="checkbox"
                    checked={filterMode}
                    onChange={handleFilterToggle}
                    className="form-check-input"
                />
            </div>

            {filterMode && <form className={styles.filterForm} onSubmit={(evt) => evt.preventDefault()}>

                {/* Date Group */}
                <div className={styles.filterGroup} >
                    <button className={styles.selectedBtn} onClick={() => handleIsOpen("date")}><MdDateRange className={styles.icon} /><span>date</span>{!isOpen.date ? <IoMdArrowDropdown className={styles.icon} /> : <IoMdArrowDropup className={styles.icon} />} </button>
                    {isOpen.date && <div className={styles.dropdown}>
                        {/* day */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="allDate"
                                onChange={() => handleChange("date", "all")}
                                checked={filterVal.date === "all"}
                            />
                            <label className="form-check-label  mx-1 mb-2" htmlFor="all">
                                All
                            </label>
                        </div>
                        {/* today */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="todayDate"
                                onChange={() => handleChange("date", "today")}
                                checked={filterVal.date === "today"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="todayDate">
                                today
                            </label>
                        </div>
                        {/* last 7 days */}
                        <div className="form-check hover-dark">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="last7Days"
                                onChange={() => handleChange("date", "last7Days")}
                                checked={filterVal.date === "last7Days"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="last7Days">
                                last 7 days
                            </label>
                        </div>
                        {/* this month */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input mb-1"
                                id="thisMonth"
                                onChange={() => handleChange("date", "thisMonth")}
                                checked={filterVal.date === "thisMonth"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="thisMonth">
                                this month
                            </label>
                        </div>
                        {/* this year */}
                        <div className="form-check">
                            <input
                                name="date"
                                type="radio"
                                className="form-check-input"
                                id="thisYear"
                                onChange={() => handleChange("date", "thisYear")}
                                checked={filterVal.date === "thisYear"}
                            />
                            <label className="form-check-label mx-1  mb-2" htmlFor="thisYear">
                                this year
                            </label>
                        </div>
                    </div>}

                </div>

                {/* others */}
                <div className={styles.filterGroup}>
                    <button className={styles.selectedBtn} onClick={() => handleIsOpen("custom")}><AiOutlinePlusSquare className={styles.icon} /> <span>more</span> {!isOpen.custom ? <IoMdArrowDropdown className={styles.icon} /> : <IoMdArrowDropup className={styles.icon} />}</button>
                    {isOpen.custom && <div className={styles.customDropdown}>

                        {/* account */}
                        <div className={styles.fullBox}>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    name="account"
                                    id="account"
                                    placeholder="Account"
                                    className={styles.customInput}
                                    ref={accountRef}
                                />
                                {filterVal.account?.length > 0 ? <IoMdCheckmarkCircle size={20} color="green" className={styles.checkmarkIcon} /> : ""}
                            </div>
                            <button className={styles.filterBtn} onClick={() => handleChange("account")}>Apply</button>
                        </div>
                        {/* amount */}
                        <div className={styles.fullBox}>
                            <div className={styles.inputBox}>
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    placeholder="Amount"
                                    className={styles.customInput}
                                    ref={amountRef}
                                />
                                {filterVal.amount?.length > 0 ? <IoMdCheckmarkCircle size={20} color="green" className={styles.checkmarkIcon} /> : ""}
                            </div>
                            <button className={styles.filterBtn} onClick={() => handleChange("amount")}>Apply</button>
                        </div>
                        {/* settlement reference */}
                        <div className={styles.fullBox}>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    name="reference"
                                    id="reference"
                                    placeholder="Reference"
                                    className={styles.customInput}
                                    ref={referenceRef}
                                />
                                {filterVal.reference?.length > 0 ? <IoMdCheckmarkCircle size={20} color="green" className={styles.checkmarkIcon} /> : ""}
                            </div>
                            <button className={styles.filterBtn} onClick={() => handleChange("reference")} > Apply</button>
                        </div>
                    </div>}

                </div>

                <button className={styles.selectedBtn} onClick={handleSubmit} style={{ backgroundColor: "#2A5885", color: "#fff" }}>SUBMIT</button>

            </form>}
        </section >
    )
}

export default Filter