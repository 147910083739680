import { useState } from "react"
import Card from "../../../shared-components/Card"
import Filter from "./filter/Filter"
import styles from "./settlements.module.css"
import Table from "./table/Table"
import Empty from "./Empty"

const Settlements = () => {

    const [isEmpty, setIsEmpty] = useState(false)

    return (
        <section className={styles.container}>

            <div className={styles.topWrapper}>
                <div className={styles.row}>
                    <Card heading="Transactions amount" body="Total amount of transactions within the specified period" amount={0} discrete={false} />

                    <Card heading="Settlement amount" body="Total amount due for settlement within the specified period" amount={0} discrete={false} />

                    <Card heading="Fees" body="Total fees within the specified period" amount={0} discrete={false} />
                </div>
                <div className={styles.row}>
                    <Card heading="Successful refunds" body="Total amount of refunds that have been successfully processed during the specified period." amount={0} />

                    <Card heading="Remitted sum" body="Total amount that will be sent to your banks within the specified period." amount={0} discrete={false} />

                    <Card heading="Reversals" body="Total amount that has been reversed into your account and included in the remitted amount." amount={0} discrete={false} />
                </div>
            </div>

            <div className={styles.mainWrapper}>
                <Filter />
                {isEmpty ? <Empty /> : <Table />
                }
            </div>
        </section>
    )
}

export default Settlements