import React, { useState, useContext } from 'react'
import styles from "./auth.module.css"
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';

import { AiFillWarning } from 'react-icons/ai';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';

import { validateEmail, validatePassword, validateString } from '../../utils/validator';

import { login } from "../../api/auth"
import { useMutation } from 'react-query';

import Swal from 'sweetalert2'

import { useDispatch } from 'react-redux';
import { updateUserToken, updateUserLogin } from "../../redux/slices/authSlice"

import { RotatingLines } from 'react-loader-spinner'

const SignIn = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch(); // Initialize useDispatch
    // const { updateUserToken, updateUserLogin } = useContext(AuthContext)

    // --------------- form validation -------------------
    const [showPassword, setShowPassword] = useState(false);
    const [hasFocused, setHasFocused] = useState(false);
    const [emailErrors, setEmailErrors] = useState({});
    const [passwordErrors, setPasswordErrors] = useState({});

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { mutate: loginFunc, isLoading } = useMutation(login, {
        onSuccess: (data) => {
            let token = data.data.token;
            localStorage.setItem("token", JSON.stringify(token));
            dispatch(updateUserToken(token));
            dispatch(updateUserLogin(true));
            navigate("/dashboard");
            Toast.fire({
                icon: 'success',
                title: 'Signed in successfully'
            });
        },
        onError: (error) => {
            const status = error.response.status
            if (status === 401) {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    title: error.message
                });
            }
        }
    })

    const initialValues = {
        email: "",
        password: ""
    }

    const [inputValues, setInputValues] = useState(initialValues)

    const handleFocus = () => {
        setHasFocused(true)
    }

    const handleBlur = () => {
        setHasFocused(false)
    }

    const handleEmail = (email) => {
        setInputValues(prev => ({ ...prev, email: email }));
        let emailResp = validateEmail(email)
        setEmailErrors(emailResp)
    };

    const handlePassword = (password) => {
        setInputValues(prev => ({ ...prev, password: password }))
        let passwordResp = validatePassword(password)
        setPasswordErrors(passwordResp)
    }

    const handleSubmit = async () => {
        if (emailErrors.numErrors === 0 && passwordErrors.numErrors === 0) {
            let signInData = {
                "email": inputValues["email"],
                "password": inputValues["password"]
            }
            loginFunc(signInData)
        }
    }

    return (
        <section className="container" style={{position: "relative"}}>
            <div className={styles.logoWrapper}>
                <span className={styles.logoText}>
                    AspaPay
                </span>
            </div>

            {isLoading && <div className={styles.loadingContainer}>
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                />
            </div>}

            <form onSubmit={(evt) => {
                evt.preventDefault()
                handleSubmit()
            }}>
                <div className='row'>
                    <div className="col-lg-12 mb-4">
                        <label htmlFor="email" className="form-label mb-0">Email</label>
                        <input type="email" className={styles.myInput} id="email" name="email" onChange={(evt) => handleEmail(evt.target.value)} value={inputValues["email"]} />

                        {emailErrors.numErrors > 0 ? < div className={styles.error}> <span className={styles.errorSpan}><AiFillWarning /></span><span className={styles.errorSpan}>{emailErrors.message}</span></div> : ""}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <label htmlFor="password" className="form-label mb-0">Password</label>

                        <div className="input-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                className={styles.passwordInput}
                                id="password"
                                name="password"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={(evt) => handlePassword(evt.target.value)}
                                value={inputValues["password"]}
                            />

                            <button
                                className={`btn ${styles.hideBtn}`}
                                type="button"
                                style={{ position: "absolute", right: "10px", fontWeight: "bold" }}
                                onClick={handleTogglePassword}
                            >
                                {showPassword ? <BsEyeSlash /> : <BsEye />}
                            </button>

                        </div>
                        {hasFocused ? (passwordErrors.minLength ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password is at least eight characters long</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password is not up to 8 characters long</span></p>) : ""}

                        {hasFocused ? (passwordErrors.hasUpperCase ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password contains at least one upper case character</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password must contain at least one upper case character</span></p>) : ""}

                        {hasFocused ? (passwordErrors.hasLowerCase ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password contains at least one lower case character</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password must contain at least one lower case character</span></p>) : ""}

                        {hasFocused ? (passwordErrors.hasNumber ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password contains at least one number</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password must contain at least one number</span></p>) : ""}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <button type="submit" className={`btn ${styles.createBtn}`}> Sign In
                        </button>
                    </div>
                    <p className={styles.alreadyParagraph}> <Link to="/reset-password" className='my-2 ml-0'>Forgot Password? </Link></p>

                </div>
            </form >
        </section >
    )

}

export default SignIn