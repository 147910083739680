import React, { lazy, Suspense } from "react";

const LazyBanner = lazy(() => import("../../components/landingAlt/banner/Banner"));
const LazyPartners = lazy(() => import("../../components/landingAlt/partners/Partners"));
const LazyWhy = lazy(() => import("../../components/landingAlt/why/Why"));
const LazyMore = lazy(() => import("../../components/landingAlt/more/More"));
const LazyTestimonials = lazy(() => import("../../components/landingAlt/testimonials/Testimonials"));
const LazyQuestions = lazy(() => import("../../components/landingAlt/questions/Questions"));
const LazyFooter = lazy(() => import("../../components/landingAlt/footer/Footer"));

const LandingAlt = () => {
    return (
        <section>
            <Suspense fallback={<div>Loading...</div>}>
                <LazyBanner />
                {/* <LazyPartners /> */}
                <LazyWhy />
                <LazyMore />
                {/* <LazyTestimonials /> */}
                <LazyQuestions />
                <LazyFooter />
            </Suspense>
        </section>
    );
};

export default LandingAlt;
