import React, { useState } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import styles from "./auth.module.css"
import { Link, useNavigate } from "react-router-dom"

import { AiFillWarning } from 'react-icons/ai';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';

import { validateEmail, validatePassword, validateString } from '../../utils/validator';

import Select from "react-select"

import { useMutation } from 'react-query';
import { register } from '../../api/auth';

import Swal from 'sweetalert2'

import { RotatingLines } from 'react-loader-spinner'

const SignUp = () => {
    const navigate = useNavigate()
    // const { updateUserToken, updateUserLogin } = useContext(AuthContext)

    const [showPassword, setShowPassword] = useState(false);
    const [hasFocused, setHasFocused] = useState(false);
    const [emailErrors, setEmailErrors] = useState({});
    const [passwordErrors, setPasswordErrors] = useState({});
    const [hasErrors, setHasErrors] = useState(true)

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { mutate: registerfn, isLoading } = useMutation(register, {
        onSuccess: (data) => {
            navigate("/login");
            Toast.fire({
                icon: 'success',
                title: data.message
            });
        },
        onError: (error) => {
            const status = error.response.status
            if (status === 401) {
                Toast.fire({
                    icon: 'error',
                    title: error.response.data.message
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    title: error.message
                });
            }
        }
    });

    const initialValues = {
        firstName: "",
        lastName: "",
        country: "nigeria",
        businessName: "",
        businessType: "startUp",
        email: "",
        password: ""
    }
    const [inputValues, setInputValues] = useState(initialValues)

    const handleFocus = () => {
        setHasFocused(true)
    }

    const handleBlur = () => {
        setHasFocused(false)
    }
    const handleEmail = (email) => {
        setInputValues(prev => ({ ...prev, email: email }));
        let emailResp = validateEmail(email)
        setEmailErrors(emailResp)
    };
    const handlePassword = (password) => {
        setInputValues(prev => ({ ...prev, password: password }))
        let passwordResp = validatePassword(password)
        setPasswordErrors(passwordResp)
    }
    const handleText = (text, field) => {
        setInputValues(prev => ({ ...prev, [field]: text }))
    }
    const handleSelect = (selectedOption, fieldName) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [fieldName]: selectedOption,
        }));
    };
    const countries = [
        { value: 'nigeria', label: 'Nigeria' },
        { value: 'ghana', label: 'Ghana' },
        { value: 'kenya', label: 'Kenya' },
    ];
    const defaultCountry = { value: 'nigeria', label: 'Nigeria' };
    const businessTypes = [
        { value: 'startUp', label: 'Start-up' },
        { value: 'registered', label: 'Registered' }
    ];
    const defaultType = { value: 'registered', label: 'Registered' };

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '4px',
        }),
    };

    const handleSubmit = async () => {
        if (emailErrors.numErrors === 0 && passwordErrors.numErrors === 0) {
            setHasErrors(false)
            let signUpData = {
                firstname: inputValues["firstName"],
                lastname: inputValues["lastName"],
                country: inputValues["country"].value,
                businessName: inputValues["businessName"],
                businessType: inputValues["businessType"].value,
                email: inputValues["email"],
                password: inputValues["password"]
            }
            registerfn(signUpData)
        }
    }

    return (

        <section className="container" >
            <div className={styles.logoWrapper}>
                <span className={styles.logoText}>
                    AspaPay
                </span>
            </div>

            {isLoading && <div className={styles.loadingContainer}>
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                />
            </div>}

            <form onSubmit={(evt) => {
                evt.preventDefault()
                handleSubmit()
            }}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="mb-4">
                            <label htmlFor="firstName" className="form-label mb-0">First Name</label>
                            <input type="text" className={styles.myInput} id="firstName" name="firstName" onChange={(evt) => handleText(evt.target.value, "firstName")}
                                value={inputValues["firstName"]} />
                            {/* <ErrorMessage name="firstName" component="span" render={msg => <div className={styles.error}> <span className={styles.errorSpan}><AiFillWarning /></span><span className={styles.errorSpan}>{msg}</span></div>} /> */}
                        </div>
                    </div>
                </div>

                <div>
                    <div className="col-lg-12">
                        <div className="mb-4">
                            <label htmlFor="lastName" className="form-label mb-0">Last Name</label>
                            <input type="text" className={styles.myInput} id="lastName" name="lastName" onChange={(evt) => handleText(evt.target.value, "lastName")}
                                value={inputValues["lastName"]} />
                            {/* <ErrorMessage name="lastName" component="span" render={msg => <div className={styles.error}> <span className={styles.errorSpan}><AiFillWarning /></span><span className={styles.errorSpan}>{msg}</span></div>} /> */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <label htmlFor="country" className="form-label mb-0">Country</label>
                        <Select
                            id="country"
                            options={countries}
                            styles={customStyles}
                            defaultValue={defaultCountry}
                            value={inputValues["country"]}
                            onChange={(selectedOption) => handleSelect(selectedOption, "country")}
                        />
                        {/* {formik.errors.country ? <div className={styles.error}> <span className={styles.errorSpan}><AiFillWarning /></span><span className={styles.errorSpan}>{formik.errors.country}</span></div> : ""} */}
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <label htmlFor="businessName" className="form-label mb-0">Business Name</label>
                        <input type="text" className={styles.myInput} id="businessName" name="businessName" onChange={(evt) => handleText(evt.target.value, "businessName")}
                            value={inputValues["businessName"]} />
                        {/* <ErrorMessage name="businessName" component="span" render={msg => <div className={styles.error}> <span className={styles.errorSpan}><AiFillWarning /></span><span className={styles.errorSpan}>{msg}</span></div>} /> */}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <label htmlFor="businessType" className="form-label mb-0">Business Type</label>
                        <Select
                            id="businessType"
                            options={businessTypes}
                            styles={customStyles}
                            defaultValue={defaultType}
                            value={inputValues["businessType"]}
                            onChange={(selectedOption) => handleSelect(selectedOption, "businessType")}
                        />
                        {/* {formik.errors.businessType ? <div className={styles.error}> <span className={styles.errorSpan}><AiFillWarning /></span><span className={styles.errorSpan}>{formik.errors.businessType}</span></div> : ""} */}
                    </div>
                </div>

                <div className='row'>
                    <div className="col-lg-12 mb-4">
                        <label htmlFor="email" className="form-label mb-0">Email</label>
                        <input type="email" className={styles.myInput} id="email" name="email" onChange={(evt) => handleEmail(evt.target.value)} value={inputValues["email"]} />
                        {emailErrors.numErrors > 0 ? < div className={styles.error}> <span className={styles.errorSpan}><AiFillWarning /></span><span className={styles.errorSpan}>{emailErrors.message}</span></div> : ""}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <label htmlFor="password" className="form-label mb-0">Password</label>
                        <div className="input-group">
                            <input type={showPassword ? "text" : "password"} className={styles.passwordInput} id="password" name="password" onFocus={handleFocus} onBlur={handleBlur} onChange={(evt) => handlePassword(evt.target.value)}
                                value={inputValues["password"]} />

                            <button
                                className={`btn ${styles.hideBtn}`}
                                type="button"
                                style={{ position: "absolute", right: "10px", fontWeight: "bold" }}
                                onClick={handleTogglePassword}
                            >
                                {showPassword ? <BsEyeSlash /> : <BsEye />}
                            </button>
                        </div>
                        {hasFocused ? (passwordErrors.minLength ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password is at least eight characters long</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password is not up to 8 characters long</span></p>) : ""}

                        {hasFocused ? (passwordErrors.hasUpperCase ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password contains at least one upper case character</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password must contain at least one upper case character</span></p>) : ""}

                        {hasFocused ? (passwordErrors.hasLowerCase ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password contains at least one lower case character</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password must contain at least one lower case character</span></p>) : ""}

                        {hasFocused ? (passwordErrors.hasNumber ? <p className={styles.goodParagraph}><IoMdCheckmarkCircle className={styles.goodIcon} /><span>Password contains at least one number</span></p> : <p className={styles.badParagraph}><MdCancel className={styles.badIcon} /><span>Password must contain at least one number</span></p>) : ""}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="form-check mb-4 px-0 d-flex">
                            <label className={styles.checkboxContainer}>
                                <input type="checkbox" checked={isChecked}
                                    onChange={handleCheckboxChange} />
                                <span className={styles.checkmark}></span>
                            </label>
                            <p className={styles.dataPolicyParagraph} htmlFor="dataPolicy">
                                By clicking <b>"Create Your Account,"</b> I acknowledge that I have read and hereby accept the terms and conditions stated in the <Link to="/terms-and-conditions">ASPAPay's Terms of Use, Merchant Agreement, and Privacy Policy.</Link>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <button type="submit" className={`btn ${styles.createBtn}`} disabled={isChecked && hasErrors ? "" : "disabled"}> Create Account</button>
                    </div>

                    <p className={styles.alreadyParagraph}>Already have an Account? <Link to="/login" className='my-2 ml-0'>Login</Link></p>

                </div>
            </form>
        </section>

    );
};

export default SignUp;