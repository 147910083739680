import styles from "../account.module.css";

const TermsAndConditions = ({ heading, subHeading }) => {
    return (
        <section className={styles.container}>
            <h4 className={styles.headingTxt}>{heading}</h4>
            <p className={styles.subHeadingTxt}>{subHeading}</p>
            <p className={styles.termsTxt}>
                Welcome to ASPAPay! These Terms and Conditions ("Terms") govern your use of the ASPAPay payment platform ("Platform"), which is owned and operated by [Compumstrics Solutions] ("we," "us," or "our"). By accessing or using the Platform, you agree to comply with these Terms. Please read them carefully.</p>

            <ol className={styles.termsList}>
                <li className={styles.itemLi}>
                    <b style={{ fontWeight: "700" }}>Account Registration and Use</b>
                    <ol>
                        <li>Eligibility: You must be at least 18 years old and have the legal capacity to enter into a binding contract to use the Platform. By registering an account, you represent and warrant that you meet these requirements.</li>
                        <li>Account Creation: You agree to provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and are solely responsible for any activity that occurs under your account.</li>
                        <li>Account Usage: You agree not to use the Platform for any unlawful, fraudulent, or unauthorized purposes. You shall not impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
                        <li>Account Termination: We reserve the right to suspend or terminate your account at our sole discretion if we believe you have violated these Terms or engaged in any activity that may harm the Platform or its users.</li>
                    </ol>
                </li>

                <li className={styles.itemLi}>
                    <b style={{ fontWeight: "700" }}>Payments</b>
                    <ol>
                        <li>Payment Authorization: By using ASPAPay, you authorize us to facilitate payments on your behalf according to the instructions you provide.</li>
                        <li>Transaction Processing: We will make reasonable efforts to ensure that transactions are processed promptly and accurately. However, we do not guarantee uninterrupted or error-free payment processing and shall not be liable for any delays, errors, or damages arising from such transactions.</li>
                        <li>Payment Disputes: If you have any issues or disputes regarding a payment, you agree to contact our customer support within a reasonable time frame to allow us to investigate and address the matter.</li>
                    </ol>
                </li>
                <li className={styles.itemLi}>
                    <b style={{ fontWeight: "700" }}>Security and Privacy</b>
                    <ol>
                        <li>Data Protection: We take the security and privacy of your personal information seriously. We employ industry-standard measures to protect your data but cannot guarantee absolute security.</li>
                        <li>Third-Party Services: The Platform may integrate with third-party services or websites. Your use of such services is subject to their respective terms and conditions and privacy policies. We are not responsible for the actions, content, or policies of these third parties.</li>
                    </ol>
                </li>

            </ol>

            <div className={styles.row}>
                <button type='button' className={styles.backBtn}>
                    back
                </button>
                <button type='submit' className={styles.saveBtn}>Save and Continue</button>
            </div>
        </section>
    )
}

export default TermsAndConditions



