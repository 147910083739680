import React from 'react';
import { Link } from 'react-router-dom'; // If using React Router
import Navbar from '../components/landing/navbar/Navbar';

const NotFoundLanding = () => {
  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <h1 style={styles.oopsHeader}>Oops!</h1>
        <h1 style={styles.header}>Page Not Found</h1>
        <p style={styles.text}>
          The page you are looking for doesn't seem to exist. Please check the URL or go back to the{' '}
          <Link to="/" style={styles.link}>
            homepage
          </Link>
          .
        </p>
        {/* You can add additional elements, illustrations, or a search bar to improve user experience */}
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    padding: '2rem',
    fontFamily: 'Arial, sans-serif',
  },
  oopsHeader: {
    fontSize: '6rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    background: `linear-gradient(45deg, #4A3C88, #1A1F34)`,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  },
  header: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  text: {
    fontSize: '1.2rem',
    textAlign: 'center',
    maxWidth: '500px',
    marginBottom: '1.5rem',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'underline',
  },
};

export default NotFoundLanding;
