// import { format } from 'date-fns'

export const COLUMNS = [
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Bank Account',
        accessor: 'bankAccount'
    },
    {
        Header: 'Amount',
        accessor: 'amount',
    },
    {
        Header: 'Settlement Reference',
        accessor: 'settlementRef',
    },
]


