import React from 'react';
import Navbar from '../../components/dashboard/navbar/Navbar';
import styles from "./layout.module.css"
import Sidebar from '../../components/dashboard/sidebar/Sidebar';

import { selectSideNavOpen } from "../../redux/slices/sharedSlice"
import { toggleSideNav } from "../../redux/slices/sharedSlice"
import { useSelector } from "react-redux";
import { useState } from "react";

const ComingSoon = () => {

    const myStyles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh',
            padding: '2rem',
            fontFamily: 'Arial, sans-serif',
            width: '100%'
        },
        oopsHeader: {
            fontSize: '4rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            background: `linear-gradient(45deg, #4A3C88, #1A1F34)`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            textAlign: "center"
        },
        header: {
            fontSize: '3rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
        },
        text: {
            fontSize: '1.2rem',
            textAlign: 'center',
            maxWidth: '500px',
            marginBottom: '1.5rem',
        },
        link: {
            color: '#007BFF',
            textDecoration: 'underline',
        },
    };

    return (
        <div style={myStyles.container}>
            <h1 style={myStyles.oopsHeader}>Coming Soon!</h1>
            <p style={myStyles.text}>
                We are working hard to bring you an amazing experience.
                The page you are looking for will be available soon.
            </p>
        </div>
    );
}

const ComingSoonDashboard = () => {
    const open = useSelector(selectSideNavOpen)

    return (
        <section className={styles.pageContainer}>
            < div className={`${open ? styles.leftBarOpen : styles.leftBarClosed}`}>
                <Sidebar />
            </div>
            <div className={`${open ? styles.rightBarOpen : styles.rightBarClosed}`}>
                <Navbar />
                <div className={styles.mainBar}>
                    <ComingSoon />
                </div>
            </div>
        </section>
    );
};

export default ComingSoonDashboard;
