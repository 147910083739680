// Styles
import styles from "./sidebar.module.css"
import classnames from "classnames"

// Icons
import { BsPerson } from 'react-icons/bs';
import { RxDashboard } from 'react-icons/rx';
import { MdOutlinePayments } from 'react-icons/md';
import { RiCustomerServiceLine } from 'react-icons/ri';
import { RiToolsFill } from 'react-icons/ri';
import { MdOutlinePeopleOutline } from 'react-icons/md';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { AiOutlineSolution } from 'react-icons/ai';
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

// React 
import { Link, useMatch } from 'react-router-dom';
import { useContext, useState, useEffect } from "react";

// Skeleton screens
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

// Api
import { getUser } from "../../../api/auth";

// React Query
import { useQuery, useQueryClient } from "react-query";

// Logout 
import { logout } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { useDispatch } from 'react-redux';
import { updateUserData, updateUserLogin } from "../../../redux/slices/authSlice"
import { BiLogOutCircle } from 'react-icons/bi';

// SideNav
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { selectIsLoggedIn, selectUserData } from "../../../redux/slices/authSlice"
import { selectSideNavOpen } from "../../../redux/slices/sharedSlice"
// import { updateUserData } from "../../../redux/slices/authSlice"
import { toggleSideNav } from "../../../redux/slices/sharedSlice"

// Logout alert
import Swal from 'sweetalert2'

const Sidebar = () => {

    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const navigate = useNavigate()

    const [isLoggedIn, setIsLoggedIn] = useState(useSelector(selectIsLoggedIn))
    const isOpen = useSelector(selectSideNavOpen)
    const userData = useSelector(selectUserData)
    const [user, setUser] = useState(userData ? userData : {})

    useEffect(() => {
        if (isLoggedIn) {
            queryClient.prefetchQuery("get-user", getUser);
        }
    }, [queryClient]);

    const { isLoading, isError, data } = useQuery(
        "get-user",
        getUser,
        {
            enabled: isLoggedIn,
            refetchOnWindowFocus: false,
            onSuccess: (user) => {
                setUser(user.data);
                dispatch(updateUserData(user.data));
            },
            onError: (error) => {
                console.log({ error });
            },
        }
    );

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { mutate: logoutFunc } = useMutation(logout, {
        onSuccess: (data) => {
            dispatch((updateUserData({})))
            dispatch((updateUserLogin(false)))
            // setShowLogout(false);
            navigate("/login");
            Toast.fire({
                icon: 'success',
                title: 'Logged out successfully'
            });
        },
        onError: (error) => {
            Toast.fire({
                icon: 'error',
                title: "unable to log out"
            });
        },
    })

    const handleLogout = () => {
        logoutFunc()
    }

    // console.log({ isOpen })

    const indexMatch = useMatch('/dashboard');
    const overviewMatch = useMatch('/dashboard/overview');
    const transactionMatch = useMatch('/dashboard/transactions');
    const customersMatch = useMatch('/dashboard/customers');
    const settlementsMatch = useMatch('/dashboard/settlements');
    const devMatch = useMatch('/dashboard/developer-tools');
    const supportMatch = useMatch('/dashboard/support');

    return (
        <>
            <section className={`${styles.container} ${!isOpen ? styles.closedContainer : ""}`}>

                {/* user information */}
                <div className={`${isOpen ? styles.userOpen : styles.userClosed}`}>
                    <div className={styles.userInfo}>
                        <span>{user?.businessName || <Skeleton />}</span>
                    </div>
                </div>

                <ul className={styles.linksWrapper}>
                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <Link to="/dashboard" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${indexMatch ? styles.active : ""}`}>
                            <BsPerson size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Account</span> : ""}
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/overview" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${overviewMatch ? styles.active : ""}`}>
                            <RxDashboard size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Dashboard</span> : ""}
                        </Link>
                    </li>
                </ul>

                <h4 className={isOpen ? styles.linkHeader : styles.hidden}>your business</h4>
                <ul>
                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <Link to="/dashboard/transactions" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${transactionMatch ? styles.active : ""}`}>
                            <MdOutlinePayments size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Transactions</span> : ""}
                        </Link>
                    </li>
                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <Link to="/dashboard/settlements" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${settlementsMatch ? styles.active : ""}`}>
                            <AiOutlineSolution size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Settlements</span> : ""}
                        </Link>
                    </li>
                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <Link to="/dashboard/customers" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${customersMatch ? styles.active : ""}`}>
                            <MdOutlinePeopleOutline size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Customers</span> : ""}
                        </Link>
                    </li>
                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <Link to="/dashboard/developer-tools" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${devMatch ? styles.active : ""}`}>
                            <RiToolsFill size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Developer Tools</span > : ""}
                        </Link>
                    </li>
                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <Link to="/dashboard/support" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${supportMatch ? styles.active : ""}`}>
                            <RiCustomerServiceLine size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Help and Support</span> : ""}
                        </Link>
                    </li>
                </ul>

                <hr />

                <ul className={styles.linksWrapper}>
                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <Link to="/dashboard/transactions" className={`${isOpen ? styles.linkItem : styles.linkItemClosed} ${transactionMatch ? styles.active : ""}`}>
                            <IoIosNotificationsOutline size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Notifications</span> : ""}
                        </Link>
                    </li>

                    <li className={` ${isOpen ? styles.listItemOpen : styles.listItemClosed}`}>
                        <button className={`${isOpen ? styles.btnOpen : styles.btnClosed}`} onClick={handleLogout}>
                            <BiLogOutCircle size={20} className={styles.linkIcon} />
                            {isOpen ? <span>Logout</span> : ""}
                        </button>
                    </li>
                </ul>

                {/* <hr /> */}
                <ul className={styles.linksWrapper}>
                    <li className={` ${isOpen ? styles.listItemOpenLast : styles.listItemClosed}`}>
                        <button className={`${isOpen ? styles.btnOpenLast : styles.btnClosedLast}`} onClick={() => { dispatch(toggleSideNav()) }}>
                            {isOpen ? <IoIosArrowBack size={20} className={styles.linkIcon} /> : <IoIosArrowForward size={20} className={styles.linkIcon} />}
                        </button>
                    </li>
                </ul>

            </section>
        </>


    )
}

export default Sidebar
// 