import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userToken: JSON.parse(localStorage.getItem("token")) || undefined,
    isLoggedIn: JSON.parse(localStorage.getItem("token")) ? true : false,
    userData: {}
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        updateUserToken: (state, action) => {
            state.userToken = action.payload;
        },
        updateUserLogin: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        updateUserData: (state, action) => {
            state.userData = action.payload;
        },
    },
});

// Create selectors for state
export const selectUserToken = state => state.auth.userToken;
export const selectIsLoggedIn = state => state.auth.isLoggedIn;
export const selectUserData = state => state.auth.userData;

// actions for state updates
export const { updateUserToken, updateUserLogin, updateUserData } = authSlice.actions;

// reducer
export default authSlice.reducer;