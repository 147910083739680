import DevTools from "../../../components/dashboard/dev-tools/DevTools"
import Navbar from "../../../components/dashboard/navbar/Navbar"
import Sidebar from "../../../components/dashboard/sidebar/Sidebar"
import styles from "../layout.module.css"

import { selectSideNavOpen } from "../../../redux/slices/sharedSlice"
import { toggleSideNav } from "../../../redux/slices/sharedSlice"
import { useSelector } from "react-redux";
import { useState } from "react";

const DevToolsPage = () => {

    const open = useSelector(selectSideNavOpen)

    return (
        <section className={styles.pageContainer}>
            <div className={`${open ? styles.leftBarOpen : styles.leftBarClosed}`}>
                <Sidebar />
            </div>
            <div className={`${open ? styles.rightBarOpen : styles.rightBarClosed}`}>
                <Navbar page="Developer Tools" />
                <div className={styles.mainBar}>
                    <DevTools />
                </div>
            </div>
        </section>
    )
}

export default DevToolsPage