// const path = "../../../assets/partners/"
import abeg from "../assets/partners/abeg.svg"
import aella from "../assets/partners/aella_credit.svg"
import alat from "../assets/partners/alat_by_wema.svg"
import branch from "../assets/partners/branch.svg"
import fliqpay from "../assets/partners/fliqpay.svg"
import konga from "../assets/partners/konga.svg"
import korapay from "../assets/partners/korapay.svg"
import kuda from "../assets/partners/kuda_bank.svg"
import lendsqr from "../assets/partners/lendsqr.svg"
import piggyvest from "../assets/partners/piggyvest.svg"
import quickteller from "../assets/partners/quickteller.svg"
import quidax from "../assets/partners/quidax.svg"
import verifi from "../assets/partners/verifi.svg"
import verve from "../assets/partners/verve.svg"
import walletsAfrica from "../assets/partners/wallets_africa.svg"
import onepipe from "../assets/partners/onepipe.svg"
import vconnect from "../assets/partners/vconnect.svg"

export const partners = [
  abeg,
  quidax,
  lendsqr,
  quickteller,
  onepipe,
  vconnect,
  aella,
  alat,
  branch,
  fliqpay,
  // konga,
  korapay,
  // kuda,
  // piggyvest,
  verifi,
  verve,
  walletsAfrica,
];