import { useContext, useState, useEffect } from "react";
import styles from "./devtools.module.css"
import Integrations from "./integrations/Integrations";
import WebHooks from "./webhooks/WebHooks";

import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/slices/authSlice";
// import { AuthContext } from "../../../context/AuthContext";

const DevTools = () => {
    const [integrations, setIntegrations] = useState(true);
    const [webHooks, setWebHooks] = useState(false);
    const userData = useSelector(selectUserData);
    const [user, setUser] = useState(userData ? userData : {});

    useEffect(() => {
        // Update the 'user' state whenever 'userData' changes
        setUser(userData);
    }, [userData]);

    const handleIntegration = () => {
        setIntegrations(true);
        setWebHooks(false);
    };

    const handleWebHooks = () => {
        setIntegrations(false);
        setWebHooks(true);
    };

    return (
        <section className={styles.container}>
            <div className={styles.btnWrapper}>
                <button className={`${styles.button}`} style={integrations ? { backgroundColor: "#2A5885", color: "#fff" } : {}} onClick={handleIntegration}>Integrations</button>

                <button className={`${styles.button}`} style={webHooks ? { backgroundColor: "#2A5885", color: "#fff" } : {}} onClick={handleWebHooks}>Webhooks</button>
            </div>

            <div className={styles.mainWrapper}>
                {integrations && <Integrations userData={user} />}

                {webHooks && <WebHooks />}
            </div>
        </section >

    )
}

export default DevTools;
