import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   sideNavOpen: false
};

const sharedSlice = createSlice({
    name: 'shared',
    initialState: initialState,
    reducers: {
        toggleSideNav: (state) => {
            state.sideNavOpen = !state.sideNavOpen;
          },  
    },
});

// Create selectors for state
export const selectSideNavOpen = state => state.shared.sideNavOpen;

// actions for state updates
export const { toggleSideNav } = sharedSlice.actions;

// reducer
export default sharedSlice.reducer;