export const settlements = [
    {
        date: "web",
        bankAccount: 3087566383,
        amount: "3,985.60",
        settlementRef: "188383bdnu373gfb46gt4y4tbfyt46gf7"
    },
    {
        date: "web",
        bankAccount: 2093475123,
        amount: "2,574.25",
        settlementRef: "8754hrt98gfr346gfdsef7834g2"
    },
    {
        date: "web",
        bankAccount: 4098237659,
        amount: "7,124.90",
        settlementRef: "32dfg64g4g6dfg8fg88g8dffd8d"
    },
    {
        date: "web",
        bankAccount: 7182937465,
        amount: "1,234.50",
        settlementRef: "y87dfg9d7fgdfgg24gsadg6sdg8d"
    },
    {
        date: "web",
        bankAccount: 5092387412,
        amount: "5,678.35",
        settlementRef: "0j7g8h6gf6g76fgd7fgdfg7dfg32"
    },
    {
        date: "web",
        bankAccount: 6123498723,
        amount: "9,876.45",
        settlementRef: "gfh7dfg78h45fg45g8h3h64g54f3"
    },
    {
        date: "web",
        bankAccount: 9012834567,
        amount: "4,321.78",
        settlementRef: "4g7h9y9u7yt8765r4tg78g7d9fd3"
    }, {
        date: "web",
        bankAccount: 1053827496,
        amount: "6,543.21",
        settlementRef: "hgfds4r3wq23edfgvbnhgt76y4r3"
    },
    {
        date: "web",
        bankAccount: 6092384756,
        amount: "8,765.43",
        settlementRef: "9u8y7t6r5t4r3ew2q1w1q1w23r4e"
    },
    {
        date: "web",
        bankAccount: 2093847563,
        amount: "2,345.67",
        settlementRef: "vcx23e4r5t6y7uhgfdcvbn7y6t5r4"
    }
];
