export const validateEmail = (email) => {
    if (isBasicString(email)) {
        if (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)) {
            return {
                numErrors: 0,
                message: "",
                isString: true
            }
        } else {
            return {
                numErrors: 1,
                message: "Invalid email format",
                isString: false
            }
        }

    } else {
        return {
            numErrors: 1,
            message: "Email must be a string",
            isString: false
        }
    }
    // TODO: validate email is a string
    // TODO: Validate emaul is of type email
}

export const validatePassword = (password) => {
    let passwordErrors = {
        minLength: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        isString: false,
        numErrors: 0
    }
    if (isBasicString(password)) {
        passwordErrors.isString = true
        if (password.length >= 8) {
            passwordErrors.minLength = true
        } else {
            passwordErrors.minLength = false
            passwordErrors.numErrors += 1
        }
        if (/[A-Z]/.test(password)) {
            passwordErrors.hasUpperCase = true
        } else {
            passwordErrors.hasUpperCase = false
            passwordErrors.numErrors += 1
        }
        if (/[a-z]/.test(password)) {
            passwordErrors.hasLowerCase = true
        } else {
            passwordErrors.hasLowerCase = false
            passwordErrors.numErrors += 1
        }
        if (/[0-9]/.test(password)) {
            passwordErrors.hasNumber = true
        } else {
            passwordErrors.hasNumber = false
            passwordErrors.numErrors += 1
        }
        return passwordErrors
    } else {
        return {
            passwordErrors
        }
    }

}

export const validateString = (stringInput) => {
    if (isBasicString(stringInput)) {
        return {
            numErrors: 0,
            errorObj: {},
            isString: true
        }
    } else {
        return {
            numErrors: 1,
            errorObj: "Field must be an input",
            isString: false
        }
    }
}

const isBasicString = (input) => {
    if (typeof input === 'string') {
        return true
    }
    return false; // If the input is not a string, return false
};