import { useState } from "react";
import styles from "./navbar.module.css"
import { BsBell } from 'react-icons/bs';

import { RiArrowDropDownLine } from 'react-icons/ri';
import { RiArrowDropUpLine } from 'react-icons/ri';

// import { logout } from "../../../api/auth";
// import { useNavigate } from "react-router-dom";
// import { useMutation } from "react-query";
// import { useDispatch } from 'react-redux';
// import { updateUserData, updateUserLogin } from "../../../redux/slices/authSlice"

// import { BiLogOutCircle } from 'react-icons/bi';

const Navbar = ({ page }) => {

  // const navigate = useNavigate()
  // const dispatch = useDispatch()

  const [productionMode, setProductionMode] = useState(false);
  const [isToggled, toggle] = useState(false);

  // const [showLogout, setShowLogout] = useState(false);

  // const handleProfileClick = () => {
  //   setShowLogout(!showLogout);
  // };

  // const handleMouseEnter = () => {
  //   setShowLogout(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowLogout(false);
  // };

  const handleToggle = () => {
    setProductionMode(!productionMode);
  };

  const callback = () => {
    toggle(!isToggled);
    // onClick(!isToggled);
  };

  // const { mutate: logoutFunc, isLoading } = useMutation(logout, {
  //   onSuccess: (data) => {
  //     dispatch((updateUserData({})))
  //     dispatch((updateUserLogin(false)))
  //     setShowLogout(false);
  //     navigate("/login");
  //   },
  //   onError: (error) => {
  //     console.log({ error })
  //   },
  // })

  // const handleLogout = () => {
  //   logoutFunc()
  // }

  return (
    <nav className={styles.container}>

      <div className={styles.wrapper}>

        <div className={styles.left}>
          <span className={styles.name}>{page}</span>
        </div>

        <div className={styles.middle} style={{
          borderColor: isToggled ? "green" : "red"
        }}>
          {!isToggled && <span className={styles.toggleTest}>Test mode</span>}
          {isToggled && <span className={styles.toggleProd}>Production mode</span>}
          <label className={styles.toggleLabel}>
            <input type="checkbox" defaultChecked={isToggled} onClick={callback} className={styles.toggleInput} />
            <span className={styles.toggleSpan}></span>
          </label>
        </div>

        <div className={styles.right}>
          <div className={styles.profileImgWrapper}>
            <span> <BsBell size={16} /></span>
            <span className={styles.notification}>6</span>
          </div>
          <div>

          </div>
          <div className={styles.profileImgWrapper}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541" alt="profile picture" className={styles.profileImg} />
          </div>
          {/* {!showLogout ? <RiArrowDropDownLine className={styles.dropdownIcon} onClick={handleProfileClick} /> : <RiArrowDropUpLine className={styles.dropdownIcon} onClick={handleProfileClick} />} */}
        </div>
        {/* {showLogout && (
          <button onClick={handleLogout} className={styles.dropdownBtn}>
            <span className={styles.dropdownBtnSpan}>
              <BiLogOutCircle size={24} />Logout
            </span>
          </button>
        )} */}

      </div>
    </nav>
  )
}

export default Navbar