import React, { useState } from "react";
import styles from "./account.module.css";
import PersonalInformation from "./personal_information/PersonalInformation";
import BusinessInformation from "./business_information/BusinessInformation";
import TermsAndConditions from "./terms_and_conditions/TermsAndConditions";
import PersonalInformationTest from "./personal_information/PersonalInformationTest";

const Account = () => {

    const [pageIndex, setPageIndex] = useState(1)

    const handleCurrPage = (idx) => {
        setPageIndex(idx)
    }

    return (
        <section className={styles.container}>

            <div className={styles.pagesDiv}>
                <button className={styles.pageBtn} style={pageIndex === 1 ? { transform: "scale(1.1)" } : {}} onClick={() => handleCurrPage(1)}>Personal Information</button>
                <button className={styles.pageBtn} style={pageIndex === 2 ? { transform: "scale(1.08)" } : {}} onClick={() => handleCurrPage(2)}>Business Information</button>
                <button className={styles.pageBtn} style={pageIndex === 3 ? { transform: "scale(1.08)" } : {}} onClick={() => handleCurrPage(3)}>Terms and Conditions</button>
            </div>

            <section className={styles.wrapper}>
                {/* <div> */}

                    {/* {pageIndex === 1 && <PersonalInformation heading="Verify your personal details" subHeading="Aspapay collects this information to verify your identity and keep your account safe" />} */}
                    {pageIndex === 1 && <PersonalInformationTest heading="Verify your personal details" subHeading="Aspapay collects this information to verify your identity and keep your account safe" />}

                    {pageIndex === 2 && <BusinessInformation heading="Business Information" subHeading="We need this information to give us an idea of the kind of business you run, the type of products/services you will need to maximize this portal and how we can generally serve you better." />}


                    {pageIndex === 3 && <TermsAndConditions heading="Terms and conditions" subHeading="These terms of use and conditions of business solutions offered by AspaPay. We kindly request you to review the following terms and conditions carefully and indicate your acceptance below." />}

                {/* </div> */}
            </section>

        </section >
    );
};

export default Account;












