import axios from "axios"

const aspapayApi = axios.create({
    baseURL: "https://api.aspapay.com"
})

// request interceptor
aspapayApi.interceptors.request.use(
    async (config) => {
        const authToken = JSON.parse(localStorage.getItem("token"))
        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return config;
    },
    (error) => {
        // Handle any request errors
        return Promise.reject(error);
    }
);


export const register = async (payload) => {
    const response = await aspapayApi.post("/auth/signup", payload)
    return response.data
}

export const login = async (payload) => {
    const response = await aspapayApi.post("/auth/login", payload)
    return response.data
}

export const getUser = async () => {
    const response = await aspapayApi.get("/auth/user")
    return response.data
}

export const logout = () => {
    localStorage.removeItem("token")
}

export default aspapayApi