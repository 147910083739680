import styles from "./auth.module.css"
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Reset = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/")
    }
    return (
        <section className={styles.resetContainer} >
            <button onClick={goBack} className={styles.homeBtn}>
                <span className={styles.homeSpan}>
                    <IoIosArrowBack size={16} /> Home
                </span>
            </button>
            <div className={styles.resetWrapper}>
                <div className={styles.logoWrapper}>
                    <span className={styles.logoText}>
                        AspaPay
                    </span>
                </div>
                <h4 className={styles.resetHeader}>Reset your password</h4>
                <p className={styles.resetPara}>Enter your email to get a reset password link</p>

                <form>

                    <div className='row'>
                        <div className="col-lg-12 mb-4">
                            <label htmlFor="email" className="form-label mb-0">Email</label>
                            <input type="email" className={styles.myInput} id="email" />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className={`btn ${styles.createBtn}`}><Link to="/reset-success" style={{ color: "inherit" }}>send reset link</Link></button>
                    </div>

                </form>
            </div>

        </section>
    )
}

export default Reset