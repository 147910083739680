// import { format } from 'date-fns'

export const COLUMNS = [
    {
        Header: 'Channel',
        accessor: 'channel',
    },
    {
        Header: 'Date',
        accessor: 'date',
    },
    {
        Header: 'Status',
        accessor: 'status',
    },
    {
        Header: 'Surcharge',
        accessor: 'surcharge',
    },
    {
        Header: 'Customer Name',
        accessor: 'customerName',
    },
    {
        Header: 'Payable Code',
        accessor: 'payableCode',
    },
    {
        Header: 'Card Number',
        accessor: 'cardNumber',
    },
    {
        Header: 'Bank',
        accessor: 'bank',
    },
    {
        Header: 'Stan',
        accessor: 'stan',
    },
]


