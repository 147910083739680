import React, { lazy, Suspense } from "react";
import Navbar from "../components/landingAlt/navbar/Navbar";

const LazyBanner = lazy(() => import("../components/landingAlt/banner/Banner"));
const LazyPartners = lazy(() => import("../components/landingAlt/partners/Partners"));
const LazyWhy = lazy(() => import("../components/landingAlt/why/Why"));
const LazyMore = lazy(() => import("../components/landingAlt/more/More"));
const LazyTestimonials = lazy(() => import("../components/landingAlt/testimonials/Testimonials"));
const LazyQuestions = lazy(() => import("../components/landingAlt/questions/Questions"));
const LazyFooter = lazy(() => import("../components/landingAlt/footer/Footer"));


const LandingAlt = () => {
    return (
        <section>
            <Suspense fallback={<div>Loading...</div>}>
                <LazyBanner headerTitle={`Who We Are`} subHeading={`We offer a safe, regular, and more convenient way to make and accept payments`} />
                {/* <LazyPartners /> */}
              
                <LazyWhy />
                <LazyMore />
                {/* <LazyTestimonials /> */}
                {/* <LazyQuestions /> */}
                <LazyFooter />
            </Suspense>
        </section>
    );
};

export default LandingAlt;