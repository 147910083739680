import styles from "./overview.module.css"

import { AiOutlineCloseSquare } from 'react-icons/ai';
import { HiOutlineDownload } from 'react-icons/hi';
import { RiLineChartFill } from 'react-icons/ri';
import { HiOutlineChartBar } from 'react-icons/hi';
import Card from "../../../shared-components/Card";

import { useQuery, useQueryClient } from "react-query";
import { getOverview } from "../../../api/apiTest";
import { useEffect, useState } from "react";

const OverviewTest = () => {

    const [overview, setOverview] = useState(
        {
            "totalRevenue": null,
            "transactionsCount": null,
            "successfulTransactions": null,
            "failedTransactions": null,
            "chart": {
                "labels": ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                "datasets": [
                    {
                        "label": "Successful Transactions",
                        "data": [5, 10, 8, 12, 15, 20],
                        "backgroundColor": "rgba(75, 192, 192, 0.2)",
                        "borderColor": "rgba(75, 192, 192, 1)",
                        "borderWidth": 1,
                        "fill": true
                    },
                    {
                        "label": "Failed Transactions",
                        "data": [1, 2, 0, 1, 0, 1],
                        "backgroundColor": "rgba(255, 99, 132, 0.2)",
                        "borderColor": "rgba(255, 99, 132, 1)",
                        "borderWidth": 1,
                        "fill": true
                    }
                ]
            }
        }
    )

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.prefetchQuery("get-overview", getOverview);
    }, [queryClient]);

    const { isLoading, isError, data } = useQuery(
        "get-overview",
        getOverview,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onSuccess: (data) => {
                setOverview(data);
                // console.log(data)
            },
            onError: (error) => {
                console.log({ error });
            },
        }
    );

    return (
        <section className={styles.container}>

            <div className={styles.topWrapper}>
                <Card icon={<HiOutlineDownload size={20} />} heading="Total Revenue" body="Total revenue generated" amount={overview?.totalRevenue} discrete={false} />

                <Card icon={<RiLineChartFill size={20} />} heading="Transactions count" body="No of transactions" amount={overview?.transactionsCount} discrete={true}  />

                <Card icon={<HiOutlineChartBar size={20} />} heading="Successful Transactions" body="No of successful transactions" amount={overview?.successfulTransactions} discrete={true}  />

                <Card icon={<AiOutlineCloseSquare size={20} />} heading="Failed Transactions" body="No of failed transactions" amount={overview?.failedTransactions} discrete={true} />
            </div>

            <div className={styles.middleWrapper}>

                {/* big card */}
                <div className={styles.bigCard}>

                </div>

                <div className={styles.cardRight}>

                </div>
            </div>



        </section>
    );
};

export default OverviewTest;