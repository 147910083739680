export const transactions = [
  {
    channel: "web",
    date: "Jun 26 2023, 13:06:42",
    status: "pending",
    surcharge: 0.0,
    customerName: "Phenomenal Softwares",
    payableCode: "MY764836HYT4",
    cardNumber: "506114******3752",
    bank: "First Bank Plc",
    stan: "0027542",
  },
  {
    channel: "web",
    date: "Jun 27 2023, 10:42:18",
    status: "completed",
    surcharge: 5.0,
    customerName: "ABC Corporation",
    payableCode: "JKL9876HGF3",
    cardNumber: "601156******4987",
    bank: "Zenith Bank Plc",
    stan: "0078965",
  },
  {
    channel: "web",
    date: "Jun 28 2023, 16:20:37",
    status: "failed",
    surcharge: 0.0,
    customerName: "XYZ Enterprises",
    payableCode: "MNO4321POI9",
    cardNumber: "400002******0321",
    bank: "Zenith Bank Plc",
    stan: "0098765",
  },
  // Add more transactions here...
  {
    channel: "web",
    date: "Jun 29 2023, 09:15:23",
    status: "completed",
    surcharge: 1,
    customerName: "PQR Industries",
    payableCode: "ZYX6543QWE8",
    cardNumber: "510510******7865",
    bank: "Zenith Bank Plc",
    stan: "0043210",
  },
  {
    channel: "web",
    date: "Jun 30 2023, 14:30:10",
    status: "pending",
    surcharge: 0,
    customerName: "LMN Technologies",
    payableCode: "FED1234CBA5",
    cardNumber: "601100******9876",
    bank: "Access Bank Plc",
    stan: "0012345",
  },
  {
    channel: "web",
    date: "Jun 30 2023, 14:30:10",
    status: "pending",
    surcharge: 0,
    customerName: "LMN Technologies",
    payableCode: "FED1234CBA5",
    cardNumber: "601100******9876",
    bank: "Access Bank Plc",
    stan: "0012345",
  },
  {
    channel: "web",
    date: "Jun 30 2023, 14:30:10",
    status: "pending",
    surcharge: 0,
    customerName: "LMN Technologies",
    payableCode: "FED1234CBA5",
    cardNumber: "601100******9876",
    bank: "Access Bank Plc",
    stan: "0012345",
  },
  {
    channel: "web",
    date: "Jun 30 2023, 14:30:10",
    status: "pending",
    surcharge: 0,
    customerName: "LMN Technologies",
    payableCode: "FED1234CBA5",
    cardNumber: "601100******9876",
    bank: "Access Bank Plc",
    stan: "0012345",
  },
  {
    channel: "web",
    date: "Jun 30 2023, 14:30:10",
    status: "pending",
    surcharge: 0,
    customerName: "LMN Technologies",
    payableCode: "FED1234CBA5",
    cardNumber: "601100******9876",
    bank: "Access Bank Plc",
    stan: "0012345",
  },
];
