import styles from "../devtools.module.css"

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const WebHooks = () => {
  return (
    <section className={styles.hookContainer}>
      <h4 className={styles.hookHeader}>Add an endpoint for your Webhook</h4>
      <form className={styles.hookCard}>
        <label htmlFor="webHook" className={styles.hookLabel}>URL for your webhook</label>
        <input type="text" name="webHook" className={styles.hookInput} />
        <button type="submit" className={styles.hookBtn}>Save</button>
      </form>
    </section>
  )
}

export default WebHooks