import axios from "axios"

axios.interceptors.request.use(
    async (config) => {
        const authToken = JSON.parse(localStorage.getItem("token"))
        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return config;
    },
    (error) => {
        // Handle any request errors
        return Promise.reject(error);
    }
);


export const register = async (payload) => {
    const response = await axios.post("https://api.aspapay.com/auth/signup", payload)
    return response.data
}

export const login = async (payload) => {
    const response = await axios.post("https://api.aspapay.com/auth/login", payload)
    return response.data
}

export const getUser = async () => {
    const response = await axios.get("https://api.aspapay.com/auth/user")
    return response.data
}

// ------------------------------- MOCK API -----------------------------
export const getUserAccount = async () => {
    // const response = await aspapayApi
    const response = await axios.get("https://demo5102846.mockable.io/user/:id")
    return response.data
}

export const getOverview = async () => {
    const response = await axios.get("https://demo5102846.mockable.io/overview ")
    return response.data
}

export const getTransactions = async () => {
    const response = await axios.get("https://demo5102846.mockable.io/transactions")
    return response.data
}

export const logout = () => {
    localStorage.removeItem("token")
}
