import styles from "./auth.module.css"
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ResetEmailSuccess = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/")
    }
    return (
        <section className={styles.resetContainer} >
            <button onClick={goBack} className={styles.homeBtn}>
                <span className={styles.homeSpan}>
                    <IoIosArrowBack size={16} /> Home
                </span>
            </button>
            <div className={styles.resetWrapper}>
                <div className={styles.logoWrapper}>
                    <span className={styles.logoText}>
                        AspaPay
                    </span>
                </div>

                <div className="col-md-12">
                    <div className={styles.tickIconWrapper}>
                        <div className={styles.tickIcon}></div>
                    </div>
                    <div className={styles.resetSuccessWrapper}>
                        <span className={styles.resetSuccessText}>Reset email successfully sent.</span >
                        <button type="submit" className={`btn ${styles.createBtn}`}><Link to="/login" style={{color: "inherit"}}>Finish</Link></button>
                    </div>
                </div>


            </div>


        </section>
    );
};

export default ResetEmailSuccess;
