import SignIn from "./SignIn"
import SignUp from "./SignUp"
import styles from "./auth.module.css"
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Auth = ({ page }) => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/")
    }

    const location = useLocation();
    const currentPathname = location.pathname;

    return (
        <div className={styles.container}>
            <button className={styles.homeBtn} onClick={goBack} >
                <span className={styles.homeSpan}>
                    <IoIosArrowBack size={16} /> Home
                </span>
            </button>
            <div className={styles.wrapper}>

                <ul className={styles.tab} id="myTab" role="tablist" >
                    <li className={styles.leftTab} >
                        <Link to="/register"><button className={styles.leftBtn} id="signup-tab" type="button" style={page === "register" ? { borderBottom: '2px solid #417fd4' } : {}}> Join Us</button>
                        </Link>                    </li>
                    <li className={styles.rightTab} >
                        <Link to="/login"> <button className={styles.rightBtn} id="profile-tab" type="button" style={page === "login" ? { borderBottom: '2px solid #417fd4' } : {}}>Sign in</button></Link>
                    </li>
                </ul>

                <div className={styles.sectionWrapper}>
                    <div style={{ height: "fit-content" }}>
                        {currentPathname === "/login" ? <SignIn /> : ""}
                        {currentPathname === "/register" ? <SignUp /> : ""}
                    </div>
                </div>

            </div>

        </div >
    )
}

export default Auth