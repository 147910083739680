import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import numeral from 'numeral';

import styles from './card.module.css';

const Card = ({ icon, heading, body, amount, discrete }) => {

    return (
        <div className={styles.card}>
            {icon && <span>{icon}</span>}
            <h4 className={styles.cardHeader}>{heading}</h4>
            <p className={styles.cardBody}>{body}</p>
            {amount !== null && amount !== undefined ? (
                <span className={styles.amount}>
                    {!discrete ? `₦${numeral(amount).format('0,0.00')}` : amount}
                </span>
            ) : (
                <Skeleton style={{ width: '4em', height: '1.5em' }} />
            )}
        </div>
    );
};

export default Card;