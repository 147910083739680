import React from 'react';

const Empty = () => {
    return (
        <div style={styles.container}>
            <h4 style={styles.header}>No transactions yet</h4>
            <p style={styles.text}>
                All transactions will be displayed here
            </p>
            {/* You can add additional elements, illustrations, or call-to-action buttons as needed */}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        margin: '1em 0em',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '2rem',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#fff',
    },
    header: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '0.6rem',
        color: '#2C3E50',
    },
    text: {
        fontSize: '1rem',
        textAlign: 'center',
        maxWidth: '500px',
        marginBottom: '1.5rem',
        color: '#333',
        fontWeight: '500'
    },
};

export default Empty;
