import styles from "./transactions.module.css"
import Filter from './filter/Filter';
import Table from './table/Table';
import { useState } from "react";
import Empty from "./Empty";
import TableTest from "./table/TableTest";

const Transactions = () => {
  const [isEmpty, setIsEmpty] = useState(false)

  return <section className={styles.container}>
    <Filter />
    {isEmpty ? <Empty /> : <Table />}
    {/* {isEmpty ? <Empty /> : <TableTest />} */}
  </section>
}

export default Transactions;