import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import sharedReducer from './slices/sharedSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    shared: sharedReducer
  },
});

export default store;