import { IoMdArrowDropdown } from 'react-icons/io';
import { Link } from "react-router-dom"

const Navbar = () => {

    return (
        <nav className="navbar navbar-expand-lg navbar-light" style={{ fontWeight: "bold", textTransform: "uppercase", height: "72px", padding: "8px 40px", backgroundColor: "#fff" }}>
            <div className="container-fluid">
                <a className="navbar-brand" href="#" style={{ fontSize: "28px", fontWeight: 700, fontStyle: "italic", color: "#2a5885", textTransform: "capitalize" }}>
                    AspaPay
                </a>

                <div className="navbar-nav d-flex gap-3" style={{ letterSpacing: "-0.6px", fontSize: "15px" }}>

                    {/* About */}
                    <div className="nav-item dropdown">
                        <a className="nav-link text-center d-flex align-items-center" href="#" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            about
                            <IoMdArrowDropdown size={20} />
                        </a>
                        {/* <ul className="dropdown-menu" aria-labelledby="aboutDropdown">
                            Dropdown content here
                        </ul> */}
                    </div>

                    {/* Payments */}
                    <div className="nav-item dropdown">
                        {/* <a className="nav-link dropdown-toggle" href="#" id="paymentsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"> */}
                        <a className="nav-link" href="#" id="paymentsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            payments

                        </a>
                        {/* <ul className="dropdown-menu" aria-labelledby="paymentsDropdown">
              Dropdown content here
            </ul> */}
                    </div>

                    {/* developer tools */}
                    <div className="nav-item dropdown">
                        <a className="nav-link text-center d-flex align-items-center" href="#" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            developer tools
                            <IoMdArrowDropdown size={20} />
                        </a>
                        {/* <ul className="dropdown-menu" aria-labelledby="toolsDropdown">
              Dropdown content here
            </ul> */}
                    </div>

                    {/* support */}
                    <div className="nav-item dropdown">
                        <a className="nav-link text-center d-flex align-items-center" href="#" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            support
                            <IoMdArrowDropdown size={20} />
                        </a>
                        {/* <ul className="dropdown-menu" aria-labelledby="supportDropdown">
              Dropdown content here
            </ul> */}
                    </div>
                </div>


                {/* log in and sign up buttons */}
                <div className="d-flex gap-3">
                    <button className="btn text-uppercase" style={{ letterSpacing: "-0.6px", fontSize: "15px" }}>
                        <Link to="/login" className="fw-bold " style={{ color: "#2a5885" }}>log in</Link>
                    </button>
                    <button className="btn" style={{ backgroundColor: "#2a5885" }} >
                        <Link to="/register" className="text-white fw-bold text-uppercase">sign up</Link>
                    </button>
                </div>

            </div>
        </nav>
    );
}

export default Navbar;
