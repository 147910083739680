import { useState } from "react";
import styles from "../devtools.module.css"
import { MdOutlineContentCopy } from 'react-icons/md';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Integrations = ({ userData }) => {

    const [showMerchCode, setShowMerchCode] = useState(false)
    const [payId, setPayId] = useState(false)
    const [dataReference, setDataReference] = useState(false)
    const [clientId, setClientId] = useState(false)
    const [secretKey, setSecretKey] = useState(false)
    const [merchId, setMerchId] = useState(false)
    const [alias, setAlias] = useState(false)

    // Function to handle text copy
    const handleCopy = async (value) => {
        try {
            await navigator.clipboard.writeText(value);
            alert('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <div className={styles.wrapper}>

            {/* Merchant credentials row */}
            <div>
                <h5 className={styles.header}>merchant credentials</h5>
                <div className={styles.row}>
                    <div className={styles.cardWrapper} onMouseEnter={() => setShowMerchCode(true)} onMouseLeave={() => setShowMerchCode(false)}
                    >
                        <div className={`card ${styles.card} `}>
                            <div className={styles.cardBody}>
                                <div>
                                    <h5 className={`card-title ${styles.id}`}>Merchant ID</h5>
                                    <p className={`card-text lead ${styles.idVal}`}>{userData?.devTool?.merchant?.merchantCode || <Skeleton />}</p>
                                </div>
                                {showMerchCode && <div className={styles.copyWrapper} onClick={() => handleCopy(userData?.devTool?.merchant?.merchantCode)}>
                                    <button className={styles.copyBtn} data-clipboard-text={userData?.devTool?.merchant?.merchantCode}>
                                        <MdOutlineContentCopy size={20} />
                                    </button>
                                    <span className="text-lowercase fs-6">copy</span>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className={styles.cardWrapper} onMouseEnter={() => setPayId(true)} onMouseLeave={() => setPayId(false)}>
                        <div className={`card ${styles.card} `} >
                            <div className={styles.cardBody}>
                                <div>
                                    <h5 className={`card-title ${styles.id}`}>Pay Item ID</h5>
                                    <p className={`card-text lead ${styles.idVal}`}>{userData?.devTool?.merchant?.payItemId || <Skeleton />}</p>
                                </div>
                                {payId && <div className={styles.copyWrapper} onClick={() => handleCopy(userData?.devTool?.merchant?.payItemId)}>
                                    <button className={styles.copyBtn} data-clipboard-text={userData?.devTool?.merchant?.payItemId} >
                                        <MdOutlineContentCopy size={20} />
                                    </button>
                                    <span className="text-lowercase fs-6">copy</span>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className={styles.cardWrapper} onMouseEnter={() => setDataReference(true)} onMouseLeave={() => setDataReference(false)}>
                        <div className={`card ${styles.card} `} >
                            <div className={styles.cardBody}>
                                <div>
                                    <h5 className={`card-title ${styles.id}`}>Data reference</h5>
                                    <p className={`card-text lead ${styles.idVal}`}>{userData?.devTool?.merchant?.dataRef || <Skeleton />}</p>
                                </div>
                                {dataReference && <div className={styles.copyWrapper}>
                                    <button className={styles.copyBtn} data-clipboard-text={userData?.devTool?.merchant?.dataRef} onClick={() => handleCopy(userData?.devTool?.merchant?.dataRef)}>
                                        <MdOutlineContentCopy size={20} />
                                    </button>
                                    <span className="text-lowercase fs-6">copy</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* API/SDK Integration */}
            <div>
                <h5 className={styles.header}>API/SDK Integration</h5>
                <div className={styles.row}>
                    <div className={styles.cardWrapper} onMouseEnter={() => setClientId(true)} onMouseLeave={() => setClientId(false)}
                    >
                        <div className={`card ${styles.card} `}>
                            <div className={styles.cardBody}>
                                <div>
                                    <h5 className={`card-title ${styles.id}`}>Client ID</h5>
                                    <p className={`card-text lead ${styles.idVal}`}>{userData?.devTool?.merchant?.merchantCode || <Skeleton />}</p>
                                </div>
                                {clientId && <div className={styles.copyWrapper}>
                                    <button className={styles.copyBtn} data-clipboard-text="ABCD1234">
                                        <MdOutlineContentCopy size={20} />
                                    </button>
                                    <span className="text-lowercase fs-6">copy</span>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className={styles.cardWrapper} onMouseEnter={() => setSecretKey(true)} onMouseLeave={() => setSecretKey(false)}>
                        <div className={`card ${styles.card} `} >
                            <div className={styles.cardBody}>
                                <div>
                                    <h5 className={`card-title ${styles.id}`}>secret key</h5>
                                    <p className={`card-text lead ${styles.idVal}`}>{userData?.devTool?.merchant?.dataRef || <Skeleton />}</p>
                                </div>
                                {secretKey && <div className={styles.copyWrapper}>
                                    <button className={styles.copyBtn} data-clipboard-text="ABCD1234">
                                        <MdOutlineContentCopy size={20} />
                                    </button>
                                    <span className="text-lowercase fs-6">copy</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>

            </div>



            <div className={styles.row}>
                {/* QR Integration */}
                <div className={styles.lastRow}>
                    <h5 className={styles.header}>QR Integration</h5>
                    <div className={styles.cardWrapper} onMouseEnter={() => setMerchId(true)} onMouseLeave={() => setMerchId(false)}
                    >
                        <div className={`card ${styles.card} `}>
                            <div className={styles.cardBody}>
                                <div>
                                    <h5 className={`card-title ${styles.id}`}>Merchant ID</h5>
                                    <p className={`card-text lead ${styles.idVal}`}>{userData?.devTool?.merchant?.merchantCode || <Skeleton />}</p>
                                </div>
                                {merchId && <div className={styles.copyWrapper}>
                                    <button className={styles.copyBtn} data-clipboard-text="ABCD1234">
                                        <MdOutlineContentCopy size={20} />
                                    </button>
                                    <span className="text-lowercase fs-6">copy</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* TILL Integration */}
                <div className={styles.lastRow}>
                    <h5 className={styles.header}>TILL Integration</h5>
                    <div className={styles.cardWrapper} onMouseEnter={() => setAlias(true)} onMouseLeave={() => setAlias(false)}
                    >
                        <div className={`card ${styles.card} `}>
                            <div className={styles.cardBody}>
                                <div>
                                    <h5 className={`card-title ${styles.id}`}>Alias</h5>
                                    <p className={`card-text lead ${styles.idVal}`}>{userData?.devTool?.merchant?.payItemId || <Skeleton />}</p>
                                </div>
                                {alias && <div className={styles.copyWrapper}>
                                    <button className={styles.copyBtn} data-clipboard-text="ABCD1234">
                                        <MdOutlineContentCopy size={20} />
                                    </button>
                                    <span className="text-lowercase fs-6">copy</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </div>
    )
}

export default Integrations