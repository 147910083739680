import React, { useEffect, useState } from "react";
import styles from "../account.module.css";
import Select from 'react-select';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useQuery, useQueryClient } from "react-query";
import { getUserAccount } from "../../../../api/apiTest";

const PersonalInformationTest = ({ heading, subHeading }) => {

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '4px',
        }),
    };
    const documents = [
        { value: 'internationalPassport', label: " International Passport" },
        { value: 'nationalIdCard', label: " National Identity Card" },
        { value: 'driversLicense', label: " Driver's License" },
        { value: 'pvc', label: "Permanent Voter's Card" },
    ];

    const [userAccount, setUserAccount] = useState({
        firstName: null,
        middleName: null,
        lastName: null,
        phone: null,
        email: null,
        bvn: null,
        dob: null,
        driversLicense: null,
        identificationNumber: null,
        identificationDocument: null
    })


    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.prefetchQuery("get-user-account", getUserAccount);
    }, [queryClient]);

    const { isLoading, isError, data } = useQuery(
        "get-user-account",
        getUserAccount,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onSuccess: (data) => {
                setUserAccount(data);
                // console.log(data)
            },
            onError: (error) => {
                console.log({ error });
            },
        }
    );

    const handleChange = (key, value) => {
        console.log(value)
        setUserAccount(prev => ({ ...prev, [key]: value }));
    };


    return (
        <section className={styles.sectionWrapper}>

            <h4 className={styles.headingTxt}>{heading}</h4>
            <p className={styles.subHeadingTxt}>{subHeading}</p>

            <form className={styles.formWrapper}>
                
                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="firstName" className={styles.label}>first name</label>
                        {/* <span>*</span> */}
                        {userAccount?.firstName ? <input type="text" name="firstName" className={styles.input} value={userAccount.firstName} onChange={(evt) => { handleChange("firstName", evt.target.value) }} /> : <Skeleton height="1.5em" />}
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="middleName" className={styles.label}>middle name</label>
                        {userAccount?.firstName ? <input type="text" name="middleName" className={styles.input} value={userAccount.middleName} onChange={(evt) => { handleChange("middleName", evt.target.value) }} /> : <Skeleton height="1.5em" />}

                    </div>
                    <div className={styles.col}>
                        <label htmlFor="lastName" className={styles.label}>last name</label>
                        {userAccount?.lastName ? <input type="text" name="lastName" className={styles.input} value={userAccount.lastName} onChange={(evt) => { handleChange("lastName", evt.target.value) }} /> : <Skeleton height="1.5em" />}
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="phone" className={styles.label}>phone</label>
                        {/* <span>*</span> */}
                        {userAccount?.phone ? <input type="phone" name="phone" className={styles.input} value={userAccount.phone} onChange={(evt) => { handleChange("phone", evt.target.value) }} /> : <Skeleton height="1.5em" />}
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="email" className={styles.label}>email</label>
                        {/* <span>*</span> */}
                        {userAccount?.email ? <input type="email" name="email" className={styles.input} value={userAccount.email} onChange={(evt) => { handleChange("email", evt.target.value) }} /> : <Skeleton height="1.5em" />}
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <label htmlFor="bvn" className={styles.label}>BVN</label>
                        {/* <span>*</span> */}
                        {userAccount?.bvn ? <input type="number" name="bvn" className={styles.input} value={userAccount.bvn} onChange={(evt) => { handleChange("bvn", evt.target.value) }} /> : <Skeleton height="1.5em" />}
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="dob" className={styles.label}>date of birth</label>
                        {/* <span>*</span> */}
                        {userAccount?.dob ? <input type="text" name="dob" className={styles.input} value={userAccount.dob} onChange={(evt) => { handleChange("dob", evt.target.value) }} /> : <Skeleton height="1.5em" />}
                    </div>
                </div>

                <div className={styles.row}>
                    {/* TODO: Make this a select element */}
                    <div className={styles.col}>
                        <label htmlFor="identification" className={styles.label}>Form of Identification</label>
                        {userAccount?.driversLicense ? (
                            <Select
                                options={documents}
                                styles={customStyles}
                                value={{ value: userAccount.driversLicense, label: userAccount.driversLicense }}
                                onChange={(selectedOption) => {
                                    handleChange("formOfIdentification", selectedOption.value);
                                }}
                            />
                        ) : (
                            <Skeleton height="1.5em" />
                        )}
                    </div>
                    <div className={styles.col}>
                        <label htmlFor="identification_number" className={styles.label}>Identification Number</label>
                        {/* <span>*</span> */}
                        {userAccount?.identificationNumber ? <input type="text" name="identification_number" className={styles.input} value={userAccount.identificationNumber} onChange={(evt) => { handleChange("identificationNumber", evt.target.value) }} /> : <Skeleton height="1.5em" />}
                    </div>
                    <div style={{ width: "40%" }}>
                        <label htmlFor="uploadId" className={styles.label}>Upload ID Document</label>
                        {userAccount?.identificationDocument ? (
                            <input type="file" className="form-control" id="uploadId" />
                        ) : (
                            <Skeleton height="1.5em" />
                        )}
                    </div>
                </div>

                <div className={styles.row}>
                    <button type='button' className={styles.backBtn}>
                        back
                    </button>
                    <button type='submit' className={styles.saveBtn}>Save and Continue</button>
                </div>

            </form>
        </section>
    )
}


export default PersonalInformationTest