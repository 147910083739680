import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { transactions } from "../../../../data/transactions"
import { COLUMNS } from './columns'

import styles from "./table.module.css"

const Table = () => {
  const columns = useMemo(() => COLUMNS, [])
  const data = useMemo(() => transactions, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  })
  // 
  return (
    <section className={styles.container}>
      <table {...getTableProps()} className='table'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}

export default Table